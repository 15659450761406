import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PageHeader from '../components/page-header'
import PageSubtitle from '../components/page-subtitle'

export default (props) => {
  const pageTitle = 'Brouchers'
  const subtitle =
    'Download catalogues or brouchers for all products offered by Kinects Ventilations.'
  const { products } = props.data.allContentfulPageProductsModal.nodes[0]

  return (
    <Layout pageURL={props.uri}>
      <SEO title={pageTitle} />
      <main>
        <PageHeader pageTitle={pageTitle} />
        <section className="container mt-5 mb-4 pb-5">
          <PageSubtitle subtitle={subtitle} />
          <div className="row justify-content-center mt-5">
            {products.map((product) => (
              <div className="col-md-3 my-3" key={product.name}>
                <div className="card">
                  <div className="card-header bg-transparent">{product.name}</div>
                  <img
                    src={`https:${product.imagesLq[0].file.url}`}
                    className="card-img-top responsive-img"
                    alt={product.name}
                    style={{ minHeight: '13rem', maxWidth: '100%' }}
                  />
                  <div className="card-footer text-center">
                    <a
                      href={product.catalogue.file.url}
                      className="btn btn-outline font-size-4 text-grey-6"
                      target="_blank"
                      rel="noopener noreferrer">
                      <i className="fas fa-download font-size-4 mr-1"></i>
                      Download broucher
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BrouchersQuery {
    allContentfulPageProductsModal {
      nodes {
        products {
          catalogue {
            file {
              url
            }
          }
          name
          imagesLq {
            file {
              url
            }
          }
        }
      }
    }
  }
`
